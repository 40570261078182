import { FLAGS } from "@/utils/flags";
import { LANG } from "@/utils/language";
import { MATCHSTATS } from "@/utils/matchstats";

export const state = () => ({
  navigation: {
    show: true,
    showHome: true,
    showApp: false,
    dark: false,
  },
  // Goalkeepers
  teamPlayersGK: Array(3).fill({
    player_number: "00",
    player_name: "Nama Pemain",
  }),
  // Defenders
  teamPlayersDF: Array(12).fill({
    player_number: "00",
    player_name: "Nama Pemain",
  }),
  // Midfielders
  teamPlayersMD: Array(8).fill({
    player_number: "00",
    player_name: "Nama Pemain",
  }),
  // Strikers
  teamPlayersST: Array(8).fill({
    player_number: "00",
    player_name: "Nama Pemain",
  }),
  device: {
    platform: "NaN",
  },
  mediaOriginUrl: "https://olahbolamedia.sgp1.digitaloceanspaces.com/",
  mediaBaseUrl: "https://olahbolamedia.sgp1.digitaloceanspaces.com/kitagaruda/",
  socials: [
    {
      src: "https://olahbolamedia.sgp1.digitaloceanspaces.com/kitagaruda/icons/instagram.png",
      url: "https://instagram.com/Timnas.Indonesia",
    },
    {
      src: "https://olahbolamedia.sgp1.digitaloceanspaces.com/kitagaruda/icons/twitter.png",
      url: "https://twitter.com/TimnasIndonesia",
    },
    {
      src: "https://olahbolamedia.sgp1.digitaloceanspaces.com/kitagaruda/icons/youtube.png",
      url: "https://youtube.com/@TimnasIndonesia_Official",
    },
    {
      src: "https://olahbolamedia.sgp1.digitaloceanspaces.com/kitagaruda/icons/facebook.png",
      url: "https://facebook.com/TimnasIndonesia",
    },
    {
      src: "https://olahbolamedia.sgp1.digitaloceanspaces.com/kitagaruda/icons/tiktok.png",
      url: "https://tiktok.com/@timnasindonesia",
    },
  ],
  tsponsor: {
    src: "https://olahbolamedia.sgp1.digitaloceanspaces.com/kitagaruda/images/timnas.png",
    url: "/",
  },
  msponsors: [
    {
      src: "https://olahbolamedia.sgp1.digitaloceanspaces.com/kitagaruda/images/bankmandiri.png",
      url: "https://bankmandiri.co.id/",
    },
    {
      src: "https://olahbolamedia.sgp1.digitaloceanspaces.com/kitagaruda/images/freeport.png",
      url: "https://ptfi.co.id/",
    },
    {
      src: "https://olahbolamedia.sgp1.digitaloceanspaces.com/kitagaruda/images/emtek.png",
      url: "https://www.vidio.com/",
    },
  ],
  osponsors_1: [
    // { src: 'https://olahbolamedia.sgp1.digitaloceanspaces.com/kitagaruda/images/bri.png', url: 'https://bri.co.id/' },
    {
      src: "https://olahbolamedia.sgp1.digitaloceanspaces.com/kitagaruda/images/astra.png",
      url: "https://www.astrafinancial.co.id/",
    },
    {
      src: "https://olahbolamedia.sgp1.digitaloceanspaces.com/kitagaruda/images/Indomilk.png",
      url: "https://www.indofood.com/",
    },
    {
      src: "https://olahbolamedia.sgp1.digitaloceanspaces.com/kitagaruda/images/indosat.png",
      url: "https://ioh.co.id/",
    },
    {
      src: "https://olahbolamedia.sgp1.digitaloceanspaces.com/kitagaruda/images/sinarmas.png",
      url: "https://www.sinarmas.com/index.php",
    },
    {
      src: "https://olahbolamedia.sgp1.digitaloceanspaces.com/kitagaruda/images/aqua.png",
      url: "https://www.sehataqua.co.id/",
    },
  ],
  osponsors_2: [
    {
      src: "https://olahbolamedia.sgp1.digitaloceanspaces.com/kitagaruda/images/erspo.png",
      url: "https://erigostore.co.id/",
    },
    {
      src: "https://olahbolamedia.sgp1.digitaloceanspaces.com/kitagaruda/images/Extrajoss.png",
      url: "https://www.extrajoss.eu/",
    },
    {
      src: "https://olahbolamedia.sgp1.digitaloceanspaces.com/kitagaruda/images/oppo.png",
      url: "https://www.oppo.com/id/",
    },
    {
      src: "https://olahbolamedia.sgp1.digitaloceanspaces.com/kitagaruda/images/Pepsodent.png",
      url: "https://www.pepsodent.com/bd/home.html",
    },
    {
      src: "https://olahbolamedia.sgp1.digitaloceanspaces.com/kitagaruda/images/Rexona.png",
      url: "https://www.rexona.com/id/",
    },
    {
      src: "https://olahbolamedia.sgp1.digitaloceanspaces.com/kitagaruda/images/Lifebuoy.png",
      url: "https://www.lifebuoy.co.id/home.html",
    },
  ],
  osponsors_3: [
    {
      src: "https://olahbolamedia.sgp1.digitaloceanspaces.com/kitagaruda/images/bookmyshow.png",
      url: "https://id.bookmyshow.com/",
    },
    {
      src: "https://olahbolamedia.sgp1.digitaloceanspaces.com/kitagaruda/images/mandiri.png",
      url: "https://www.inhealth.co.id/",
    },
    {
      src: "https://olahbolamedia.sgp1.digitaloceanspaces.com/kitagaruda/images/mitra.png",
      url: "https://www.mitrakeluarga.com/",
    },
    {
      src: "https://olahbolamedia.sgp1.digitaloceanspaces.com/kitagaruda/images/bocorocco.png",
      url: "https://bocorocco-online.com/",
    },
    {
      src: "https://olahbolamedia.sgp1.digitaloceanspaces.com/kitagaruda/images/paxel.png",
      url: "https://paxel.co/",
    },
    // { src: 'https://olahbolamedia.sgp1.digitaloceanspaces.com/kitagaruda/images/pocari.png', url: 'https://www.pocarisweat.id/' },
    {
      src: "https://olahbolamedia.sgp1.digitaloceanspaces.com/kitagaruda/images/oxygen.png",
      url: "https://www.oxygen.id/",
    },
    {
      src: "https://olahbolamedia.sgp1.digitaloceanspaces.com/kitagaruda/images/specs.png",
      url: "https://www.specs.id/",
    },
  ],
  voteCategories: [
    { id: 0, name: "VOTING" },
    { id: 1, name: "DISKUSI" },
    { id: 2, name: "LOREM IPSUM" },
    { id: 3, name: "LOREM IPSUM" },
  ],
  defaultVoteOptions: [
    { name: "Opsi 1", photo: "", uids: [], votes: 0, checked: false },
    { name: "Opsi 2", photo: "", uids: [], votes: 0, checked: false },
    { name: "Opsi 3", photo: "", uids: [], votes: 0, checked: false },
    { name: "Opsi 4", photo: "", uids: [], votes: 0, checked: false },
    { name: "Opsi 5", photo: "", uids: [], votes: 0, checked: false },
    { name: "Opsi 6", photo: "", uids: [], votes: 0, checked: false },
    { name: "Opsi 7", photo: "", uids: [], votes: 0, checked: false },
    { name: "Opsi 8", photo: "", uids: [], votes: 0, checked: false },
    { name: "Opsi 9", photo: "", uids: [], votes: 0, checked: false },
    { name: "Opsi 10", photo: "", uids: [], votes: 0, checked: false },
    { name: "Opsi 11", photo: "", uids: [], votes: 0, checked: false },
    { name: "Opsi 12", photo: "", uids: [], votes: 0, checked: false },
    { name: "Opsi 13", photo: "", uids: [], votes: 0, checked: false },
    { name: "Opsi 14", photo: "", uids: [], votes: 0, checked: false },
    { name: "Opsi 15", photo: "", uids: [], votes: 0, checked: false },
    { name: "Opsi 16", photo: "", uids: [], votes: 0, checked: false },
    { name: "Opsi 17", photo: "", uids: [], votes: 0, checked: false },
    { name: "Opsi 18", photo: "", uids: [], votes: 0, checked: false },
    { name: "Opsi 19", photo: "", uids: [], votes: 0, checked: false },
    { name: "Opsi 20", photo: "", uids: [], votes: 0, checked: false },
  ],
  voteOptions: [],
  voteOptionColors: [
    "blue",
    "#FFEB3B",
    "#8E30FF",
    "#2D7F81",
    "#C17E57",
    "#8C8D79",
    "#440B24",
    "#9CBC8D",
    "#FD0903",
    "#1E8364",
    "#456372",
    "#52D58D",
    "#6C4FC5",
    "#4CE24D",
    "#A6A7F7",
    "#6DC2A6",
    "#FF5722",
    "#B6DC3E",
    "#AA4444",
    "#FFC107",
  ],
  teams: [
    { id: 0, name: "PUTRA" },
    { id: 1, name: "PUTRI" },
    { id: 2, name: "SEPAKBOLA PANTAI" },
    { id: 3, name: "ESPORT" },
  ],
  teamGroups: [
    { id: 0, name: "TIMNAS PUTRA" },
    { id: 1, name: "TIMNAS PUTRI" },
    { id: 2, name: "TIMNAS SEPAKBOLA PANTAI" },
    { id: 3, name: "ESPORT" },
  ],
  teamCategories: [
    { id: 0, name: "SENIOR" },
    { id: 1, name: "U-23" },
    { id: 2, name: "U-20" },
    { id: 3, name: "U-17" },
  ],
  teamPlayerPositions: [
    { id: 0, name: "PENJAGA GAWANG" },
    { id: 1, name: "PEMAIN BELAKANG" },
    { id: 2, name: "PEMAIN TENGAH" },
    { id: 3, name: "PEMAIN DEPAN" },
  ],
  teamOfficialPositions: [
    { id: 0, name: "PELATIH KEPALA" },
    { id: 1, name: "ASISTEN PELATIH" },
    { id: 2, name: "KITMAN" },
    { id: 3, name: "DOKTER" },
    { id: 4, name: "SEKTIM" },
    { id: 5, name: "MANAGER" },
    { id: 6, name: "INTERPRETER" },
    { id: 7, name: "ANALYST" },
    { id: 8, name: "FISIOTRAPIS" },
  ],
  productSizes: [
    { id: 39, name: "XS" },
    { id: 40, name: "S" },
    { id: 41, name: "M" },
    { id: 42, name: "L" },
    { id: 43, name: "XL" },
    { id: 44, name: "XXL" },
  ],
  productCategories: [
    { id: 0, name: "SPORT APPAREL" },
    { id: 1, name: "MERCHANDISE" },
    { id: 2, name: "CASUAL ATTIRE" },
    { id: 3, name: "ACCESSORIES" },
    { id: 4, name: "FOOTWEAR" },
  ],
  ticketCategories: [{ id: 0, name: "TICKET" }],
  newsCategories: [
    { id: 0, name: "Umum" },
    { id: 1, name: "Timnas" },
    { id: 2, name: "Suporter" },
  ],
  videoCategories: [
    { id: 0, name: "Match Highlights", value: "match-highlights" },
    { id: 1, name: "Match Features", value: "match-features" },
    { id: 2, name: "News", value: "news" },
    { id: 3, name: "Features", value: "features" },
    { id: 4, name: "Profiles", value: "profiles" },
    { id: 5, name: "Others", value: "others" },
  ],
  videoTagLeagues: [
    { name: "Timnas Senior", id: 0, league_id: 1204, slug: "timnas-senior" },
    { name: "Timnas U-23", id: 1, league_id: 1399, slug: "timnas-u23" },
    { name: "Timnas U-20", id: 2, league_id: 1269, slug: "timnas-u20" },
    { name: "Timnas U-19", id: 3, league_id: 1229, slug: "timnas-u19" },
    { name: "Timnas U-16", id: 4, league_id: 1252, slug: "timnas-u16" },
    { name: "Timnas Wanita", id: 5, league_id: 0, slug: "timnas-wanita" },
    {
      name: "Timnas Wanita U-19",
      id: 6,
      league_id: 1056,
      slug: "timnas-wanita-u19",
    },
    {
      name: "Timnas Sepakbola Pantai",
      id: 7,
      league_id: 0,
      slug: "timnas-sepakbola-pantai",
    },
    { name: "Timnas E-Sport", id: 8, league_id: 1005, slug: "timnas-esports" },
    { name: "Lainnya", id: 9, league_id: 1007, slug: "others" },
  ],
  showFilters: [
    { name: "50", id: 50 },
    { name: "100", id: 100 },
    { name: "200", id: 200 },
    { name: "300", id: 300 },
    { name: "400", id: 400 },
    { name: "500", id: 500 },
  ],
  statusLists: [
    { name: "Draft", id: 0 },
    { name: "Approved", id: 1 },
    { name: "Published", id: 2 },
    { name: "Paused", id: 3 },
    { name: "Expired", id: 4 },
    { name: "Cancelled", id: 5 },
  ],
  tagLeagues: [
    // {name: 'Liga Inggris', id: 0, league_id: 1204, slug: 'liga-inggris' },
    // {name: 'Liga Spanyol', id: 1, league_id: 1399, slug: 'liga-spanyol' },
    // {name: 'Liga Italia', id: 2, league_id: 1269, slug: 'liga-italia' },
    // {name: 'Liga Jerman', id: 3, league_id: 1229, slug: 'liga-jerman' },
    // {name: 'Liga 1', id: 4, league_id: 1252, slug: 'liga-1' },
    { name: "Timnas", id: 5, league_id: 0, slug: "timnas" },
    // {name: 'Dunia', id: 6, league_id: 1056, slug: 'dunia' },
    // {name: 'Internasional', id: 7, league_id: 0, slug: 'internasional'},
    // {name: 'Liga Champions', id: 8, league_id: 1005, slug: 'liga-champions' },
    // {name: 'Liga Eropah', id: 9, league_id: 1007, slug: 'liga-eropah' },
  ],
  tagCategory: [
    // {name: 'Berita Terkini', id: 0},
    // {name: 'Fakta Menarik', id: 2},
    { name: "Cerita Bola", id: 4 },
    { name: "Profil Fans", id: 5 },
  ],
  players: [],
  officials: [],
  user: {
    uid: "",
    docId: "",
    email: "",
    displayName: "",
    nik: "",
    created: "",
    selectedProvince: null,
    selectedCity: null,
    selectedDistrict: null,
    selectedVillage: null,
    ktp: "",
    fullAddress: "",
    dateBirth: "",
    placeBirth: "",
    postalCode: "",
    aboutMe: "",
    fullName: "",
    address: "",
    dob: "",
    sex: 0,
    gravatar: false,
    avatar: "",
    hasCoinbase: false,
    hasWeb3InjectedBrowser: false,
    isConnectedToApprovedNetwork: false,
    isLoggedIn: false,
    isVerified: false,
    idVerified: false,
    emailVerified: false,
    phoneVerified: false,
    accLevel: 0,
    accType: 0,
    accStatus: 0,
    memberSince: 0,
    myLeagues: [],
    myTeams: [],
    myTeamsUpdated: 0,
    isDAppReady: false,
    isValidUserBut: "0",
    originalIsValidUserBut: "0",
    language: "id",
    favorites: [],
    mymatches: [],
    kicktip: false,
    espl: false,
    voted: false,
    esplCount: 0,
    promo_code: "",
    platform: "",
  },
  authUser: null,
  loading: false,
  fileseq: 0,
  fileprogress: 0,
  error: null,
  contractInstance: null,
  lang: LANG,
  flags: FLAGS,
  matchstats: MATCHSTATS,
  match: [],
  matchTab: 7,
  ktLoaded: false,
  key: "d9a822bc-2b12-4f50-9c1f-aff89cc21a7a",
  totalUsers: {
    total: 0,
    today: 0,
    month: 0,
  },
  totalUsersEspl: 0,
});

// This is look like events.
export const mutations = {
  // GK
  setPlayerGK(state, { index, player }) {
    state.teamPlayersGK.splice(index, 1, player);
  },
  setPlayersGK(state, players) {
    state.teamPlayersGK =
      players.length >= 3
        ? players
        : [
            ...players,
            ...Array(3 - players.length).fill({
              player_number: "00",
              player_name: "Nama Pemain",
            }),
          ];
  },

  // DF
  setPlayerDF(state, { index, player }) {
    state.teamPlayersDF.splice(index, 1, player);
  },
  setPlayersDF(state, players) {
    state.teamPlayersDF =
      players.length >= 12
        ? players
        : [
            ...players,
            ...Array(12 - players.length).fill({
              player_number: "00",
              player_name: "Nama Pemain",
            }),
          ];
  },

  // MD
  setPlayerMD(state, { index, player }) {
    state.teamPlayersMD.splice(index, 1, player);
  },
  setPlayersMD(state, players) {
    state.teamPlayersMD =
      players.length >= 8
        ? players
        : [
            ...players,
            ...Array(8 - players.length).fill({
              player_number: "00",
              player_name: "Nama Pemain",
            }),
          ];
  },

  // ST
  setPlayerST(state, { index, player }) {
    state.teamPlayersST.splice(index, 1, player);
  },
  setPlayersST(state, players) {
    state.teamPlayersST =
      players.length >= 8
        ? players
        : [
            ...players,
            ...Array(8 - players.length).fill({
              player_number: "00",
              player_name: "Nama Pemain",
            }),
          ];
  },

  ShowNav(state) {
    state.navigation.show = true;
  },
  HideNav(state) {
    state.navigation.show = false;
  },
  ShowNavHome(state) {
    state.navigation.showHome = true;
    state.navigation.showApp = false;
  },
  ShowNavApp(state) {
    state.navigation.showHome = false;
    state.navigation.showApp = true;
  },
  SetDevice(state, payload) {
    state.device.platform = payload.platform;
  },
  SetTotalUsersCount(state, payload) {
    state.totalUsers = payload.count;
  },
  SetTotalEsplCount(state, payload) {
    state.totalUsersEspl = payload.count;
  },
  SetMatch(state, payload) {
    state.match = payload;
  },
  SetMatchTab(state, payload) {
    state.matchTab = payload;
  },
  SetEsplCount(state, payload) {
    state.user.esplCount = payload.count;
  },
  AddMyMatch(state, payload) {
    state.user.mymatches.push(payload);
  },
  UpdateMyMatch(state, payload) {
    let index = state.user.mymatches.findIndex(
      (item) => item["@fix_id"] === payload["@fix_id"]
    );
    state.user.mymatches[index] = payload;
  },
  RemoveMyMatch(state, payload) {
    // payload === index
    state.user.mymatches.splice(payload, 1);
  },
  AddTeamPlayers(state, payload) {
    state.players.push(payload);
  },
  UpdateTeamPlayers(state, payload) {
    if (state.players.length > 0) {
      const index = state.players.findIndex(
        (item) => item["idx"] === payload["idx"]
      );
      if (index > -1) {
        state.players[index] = payload;
      }
    } else {
      state.players.push(payload);
    }
  },
  initialTeamPlayers(state, payload) {
    state.players = payload;
  },
  AddTeamOfficials(state, payload) {
    state.officials.push(payload);
  },
  UpdateTeamOfficials(state, payload) {
    if (state.officials.length > 0) {
      const index = state.officials.findIndex(
        (item) => item["idx"] === payload["idx"]
      );
      if (index > -1) {
        state.officials[index] = payload;
      }
    } else {
      state.officials.push(payload);
    }
  },
  initialTeamOfficials(state, payload) {
    state.officials = payload;
  },
  AddVoteOptions(state, payload) {
    state.voteOptions.push(payload);
  },
  UpdateVoteOptions(state, payload) {
    if (state.voteOptions.length > 0) {
      const index = state.voteOptions.findIndex(
        (item) => item.idx === payload.idx
      );
      if (index > -1) {
        state.voteOptions[index] = payload;
      }
    } else {
      state.voteOptions.push(payload);
    }
  },
  RemoveVoteOptions(state, payload) {
    // payload === index
    const index = state.voteOptions.findIndex(
      (item) => item.idx === payload.idx
    );
    state.voteOptions.splice(index, 1);
  },
  initialVoteOptions(state, payload) {
    state.voteOptions = payload;
  },
  SetLanguage(state, payload) {
    state.user.language = payload.language;
  },
  SetUserDetails(state, payload) {
    state.user.uid = payload.uid;
    state.user.docId = payload.docId;
    state.user.language = payload.language;
    state.user.displayName = payload.displayName;
    state.user.nik = payload.nik;
    state.user.created = payload.created;
    state.user.selectedProvince = payload.selectedProvince;
    state.user.selectedCity = payload.selectedCity;
    state.user.selectedDistrict = payload.selectedDistrict;
    state.user.selectedVillage = payload.selectedVillage;
    state.user.ktp = payload.ktp;
    state.user.fullAddress = payload.fullAddress;
    state.user.dateBirth = payload.dateBirth;
    state.user.placeBirth = payload.placeBirth;
    state.user.postalCode = payload.postalCode;
    state.user.aboutMe = payload.aboutMe;
    state.user.fullName = payload.fullName;
    state.user.dob = payload.dob;
    state.user.sex = payload.sex;
    state.user.address = payload.address;
    state.user.email = payload.email;
    state.user.gravatar = payload.gravatar;
    state.user.avatar = payload.avatar;
    state.user.isLoggedIn = payload.isLoggedIn;
    state.user.isVerified = payload.isVerified;
    state.user.idVerified = payload.idVerified;
    state.user.emailVerified = payload.emailVerified;
    state.user.phoneVerified = payload.phoneVerified;
    state.user.accLevel = payload.accLevel;
    state.user.accType = payload.accType;
    state.user.accStatus = payload.accStatus;
    state.user.memberSince = payload.memberSince;
    state.user.favorites = payload.favorites;
    state.user.myLeagues = payload.myLeagues;
    state.user.myTeams = payload.myTeams;
    state.user.myTeamsUpdated = payload.myTeamsUpdated;
    state.user.kicktip = payload.kicktip;
    state.user.espl = payload.espl;
    state.user.promo_code = payload.promo_code;
    state.user.platform = payload.platform;
    state.user.ellevate_user = payload.ellevate_user;
    state.user.date_subscription_started = payload.date_subscription_started;
    state.user.date_of_last_renewal = payload.date_of_last_renewal;
    state.user.payment_code = payload.payment_code;
  },
  UPDATE_USER_PROP(state, payload) {
    state.user = { ...state.user, ...payload };
  },
  setAvatar(state, payload) {
    state.user.gravatar = false;
    state.user.avatar = payload;
  },
  setVoted(state, payload) {
    state.user.voted = payload;
  },
  SetFileProgress(state, payload) {
    state.fileseq = payload.fileseq;
    state.fileprogress = payload.fileprogress;
  },
  setLoading(state, payload) {
    state.loading = payload;
  },
  setError(state, payload) {
    state.error = payload;
  },
  clearError(state) {
    state.error = null;
  },
  ktLoaded(state) {
    state.ktLoaded = true;
  },
  setUser(state, user) {
    state.authUser = user;
  },
  ON_AUTH_STATE_CHANGED(state, { authUser }) {
    if (!authUser) {
      state.authUser = null;
    } else {
      const { uid, email, emailVerified, displayName } = authUser;
      state.authUser = {
        uid,
        email,
        emailVerified,
        displayName,
      };
    }
  },
};

export const actions = {
  // TIMNAS PUTRA:
  // Get data timnas putra senior
  async fetchTimnasPutraSenior({ commit }) {
    const response = await this.$axios.$get("/api/team-squad");
    response.sort((a, b) => a.created - b.created);

    // Memfilter pemain berdasarkan posisi dan kategori pemain
    const goalkeepers = response.filter(
      (player) =>
        player.player_position === "Goalkeepers" &&
        player.player_category === "Senior"
    );
    const defenders = response.filter(
      (player) =>
        player.player_position === "Defenders" &&
        player.player_category === "Senior"
    );
    const midfielders = response.filter(
      (player) =>
        player.player_position === "Midfielders" &&
        player.player_category === "Senior"
    );
    const strikers = response.filter(
      (player) =>
        player.player_position === "Strikers" &&
        player.player_category === "Senior"
    );

    commit("setPlayersGK", goalkeepers);
    commit("setPlayersDF", defenders);
    commit("setPlayersMD", midfielders);
    commit("setPlayersST", strikers);
  },

  // Get data timnas putra u-23
  async fetchTimnasPutraU23({ commit }) {
    const response = await this.$axios.$get("/api/team-squad");
    response.sort((a, b) => a.created - b.created);

    // Memfilter pemain berdasarkan posisi dan kategori pemain
    const goalkeepers = response.filter(
      (player) =>
        player.player_position === "Goalkeepers" &&
        player.player_category === "U23"
    );
    const defenders = response.filter(
      (player) =>
        player.player_position === "Defenders" &&
        player.player_category === "U23"
    );
    const midfielders = response.filter(
      (player) =>
        player.player_position === "Midfielders" &&
        player.player_category === "U23"
    );
    const strikers = response.filter(
      (player) =>
        player.player_position === "Strikers" &&
        player.player_category === "U23"
    );

    commit("setPlayersGK", goalkeepers);
    commit("setPlayersDF", defenders);
    commit("setPlayersMD", midfielders);
    commit("setPlayersST", strikers);
  },

  // Get data timnas putra u-20
  async fetchTimnasPutraU20({ commit }) {
    const response = await this.$axios.$get("/api/team-squad");
    response.sort((a, b) => a.created - b.created);

    // Memfilter pemain berdasarkan posisi dan kategori pemain
    const goalkeepers = response.filter(
      (player) =>
        player.player_position === "Goalkeepers" &&
        player.player_category === "U20"
    );
    const defenders = response.filter(
      (player) =>
        player.player_position === "Defenders" &&
        player.player_category === "U20"
    );
    const midfielders = response.filter(
      (player) =>
        player.player_position === "Midfielders" &&
        player.player_category === "U20"
    );
    const strikers = response.filter(
      (player) =>
        player.player_position === "Strikers" &&
        player.player_category === "U20"
    );

    commit("setPlayersGK", goalkeepers);
    commit("setPlayersDF", defenders);
    commit("setPlayersMD", midfielders);
    commit("setPlayersST", strikers);
  },

  // Get data timnas putra u-17
  async fetchTimnasPutraU17({ commit }) {
    const response = await this.$axios.$get("/api/team-squad");
    response.sort((a, b) => a.created - b.created);

    // Memfilter pemain berdasarkan posisi dan kategori pemain
    const goalkeepers = response.filter(
      (player) =>
        player.player_position === "Goalkeepers" &&
        player.player_category === "U17"
    );
    const defenders = response.filter(
      (player) =>
        player.player_position === "Defenders" &&
        player.player_category === "U17"
    );
    const midfielders = response.filter(
      (player) =>
        player.player_position === "Midfielders" &&
        player.player_category === "U17"
    );
    const strikers = response.filter(
      (player) =>
        player.player_position === "Strikers" &&
        player.player_category === "U17"
    );

    commit("setPlayersGK", goalkeepers);
    commit("setPlayersDF", defenders);
    commit("setPlayersMD", midfielders);
    commit("setPlayersST", strikers);
  },

  async addPlayer({ commit }, { position, index, player }) {
    // Kirim ke API sesuai dengan posisi
    try {
      const response = await this.$axios.$post("/api/team-squad", player);
      console.log(response);

      if (position === "Goalkeepers") {
        commit("setPlayerGK", { index, player });
      } else if (position === "Defenders") {
        commit("setPlayerDF", { index, player });
      } else if (position === "Midfielders") {
        commit("setPlayerMD", { index, player });
      } else if (position === "Strikers") {
        commit("setPlayerST", { index, player });
      }
    } catch (error) {
      if (error.response && error.response.data.code === 11000) {
        // Cek apakah error adalah duplicate key error
        throw new Error("duplicate"); // Lempar error baru untuk ditangani di modal
      }
      throw error; // Lempar error lain untuk penanganan umum
    }
  },

  async updatePlayer({ commit }, { id, player }) {
    try {
      const response = await this.$axios.$put(`/api/team-squad/${id}`, player);
      console.log("Player updated:", response);
      return response; // Kembalikan response untuk digunakan jika diperlukan
    } catch (error) {
      if (error.response && error.response.data.code === 11000) {
        // Cek apakah error adalah duplicate key error
        throw new Error("duplicate"); // Lempar error baru untuk ditangani di modal
      }
      throw error; // Lempar error lain untuk penanganan umum
    }
  },

  async deletePlayer({ commit }, { id }) {
    const response = await this.$axios.$delete(`/api/team-squad/${id}`);
    console.log("Player deleted:", response);
    return response; // Kembalikan response untuk digunakan jika diperlukan
  },

  // TIMNAS PUTRI:
  // Get data timnas putri senior
  async fetchTimnasPutriSenior({ commit }) {
    const response = await this.$axios.$get("/api/women-team-squad");
    response.sort((a, b) => a.created - b.created);

    // Memfilter pemain berdasarkan posisi dan kategori pemain
    const goalkeepers = response.filter(
      (player) =>
        player.player_position === "Goalkeepers" &&
        player.player_category === "Senior"
    );
    const defenders = response.filter(
      (player) =>
        player.player_position === "Defenders" &&
        player.player_category === "Senior"
    );
    const midfielders = response.filter(
      (player) =>
        player.player_position === "Midfielders" &&
        player.player_category === "Senior"
    );
    const strikers = response.filter(
      (player) =>
        player.player_position === "Strikers" &&
        player.player_category === "Senior"
    );

    commit("setPlayersGK", goalkeepers);
    commit("setPlayersDF", defenders);
    commit("setPlayersMD", midfielders);
    commit("setPlayersST", strikers);
  },

  // Get data timnas putri U23
  async fetchTimnasPutriU23({ commit }) {
    const response = await this.$axios.$get("/api/women-team-squad");
    response.sort((a, b) => a.created - b.created);

    // Memfilter pemain berdasarkan posisi dan kategori pemain
    const goalkeepers = response.filter(
      (player) =>
        player.player_position === "Goalkeepers" &&
        player.player_category === "U23"
    );
    const defenders = response.filter(
      (player) =>
        player.player_position === "Defenders" &&
        player.player_category === "U23"
    );
    const midfielders = response.filter(
      (player) =>
        player.player_position === "Midfielders" &&
        player.player_category === "U23"
    );
    const strikers = response.filter(
      (player) =>
        player.player_position === "Strikers" &&
        player.player_category === "U23"
    );

    commit("setPlayersGK", goalkeepers);
    commit("setPlayersDF", defenders);
    commit("setPlayersMD", midfielders);
    commit("setPlayersST", strikers);
  },

  // Get data timnas putri U20
  async fetchTimnasPutriU20({ commit }) {
    const response = await this.$axios.$get("/api/women-team-squad");
    response.sort((a, b) => a.created - b.created);

    // Memfilter pemain berdasarkan posisi dan kategori pemain
    const goalkeepers = response.filter(
      (player) =>
        player.player_position === "Goalkeepers" &&
        player.player_category === "U20"
    );
    const defenders = response.filter(
      (player) =>
        player.player_position === "Defenders" &&
        player.player_category === "U20"
    );
    const midfielders = response.filter(
      (player) =>
        player.player_position === "Midfielders" &&
        player.player_category === "U20"
    );
    const strikers = response.filter(
      (player) =>
        player.player_position === "Strikers" &&
        player.player_category === "U20"
    );

    commit("setPlayersGK", goalkeepers);
    commit("setPlayersDF", defenders);
    commit("setPlayersMD", midfielders);
    commit("setPlayersST", strikers);
  },

  // Get data timnas putri U17
  async fetchTimnasPutriU17({ commit }) {
    const response = await this.$axios.$get("/api/women-team-squad");
    response.sort((a, b) => a.created - b.created);

    // Memfilter pemain berdasarkan posisi dan kategori pemain
    const goalkeepers = response.filter(
      (player) =>
        player.player_position === "Goalkeepers" &&
        player.player_category === "U17"
    );
    const defenders = response.filter(
      (player) =>
        player.player_position === "Defenders" &&
        player.player_category === "U17"
    );
    const midfielders = response.filter(
      (player) =>
        player.player_position === "Midfielders" &&
        player.player_category === "U17"
    );
    const strikers = response.filter(
      (player) =>
        player.player_position === "Strikers" &&
        player.player_category === "U17"
    );

    commit("setPlayersGK", goalkeepers);
    commit("setPlayersDF", defenders);
    commit("setPlayersMD", midfielders);
    commit("setPlayersST", strikers);
  },

  async addPlayerTimnasPutri({ commit }, { position, index, player }) {
    // Kirim ke API sesuai dengan posisi
    try {
      const response = await this.$axios.$post("/api/women-team-squad", player);
      console.log(response);

      if (position === "Goalkeepers") {
        commit("setPlayerGK", { index, player });
      } else if (position === "Defenders") {
        commit("setPlayerDF", { index, player });
      } else if (position === "Midfielders") {
        commit("setPlayerMD", { index, player });
      } else if (position === "Strikers") {
        commit("setPlayerST", { index, player });
      }
    } catch (error) {
      if (error.response && error.response.data.code === 11000) {
        // Cek apakah error adalah duplicate key error
        throw new Error("duplicate"); // Lempar error baru untuk ditangani di modal
      }
      throw error; // Lempar error lain untuk penanganan umum
    }
  },

  async updatePlayerTimnasPutri({ commit }, { id, player }) {
    try {
      const response = await this.$axios.$put(
        `/api/women-team-squad/${id}`,
        player
      );
      console.log("Player updated:", response);
      return response; // Kembalikan response untuk digunakan jika diperlukan
    } catch (error) {
      if (error.response && error.response.data.code === 11000) {
        // Cek apakah error adalah duplicate key error
        throw new Error("duplicate"); // Lempar error baru untuk ditangani di modal
      }
      throw error; // Lempar error lain untuk penanganan umum
    }
  },

  async deletePlayerTimnasPutri({ commit }, { id }) {
    const response = await this.$axios.$delete(`/api/women-team-squad/${id}`);
    console.log("Player deleted:", response);
    return response; // Kembalikan response untuk digunakan jika diperlukan
  },

  clearError({ commit }) {
    commit("clearError");
  },
  onAuthStateChanged(ctx, { authUser, claims }) {
    if (!authUser) {
      // claims = null
      // Perform logout operations
    } else {
      // Do something with the authUser and the claims object...
    }
  },
};

export const getters = {
  getDevice(state) {
    return state.device;
  },
  loading(state) {
    return state.loading;
  },
  error(state) {
    return state.error;
  },
  isLoggedIn(state) {
    return !!state.authUser;
  },
  authUser(state) {
    return state.authUser;
  },
  isAdmin(state) {
    return state.auth?.loggedIn; // auth object as default will be added in vuex state, when you initialize nuxt auth
  },
  getUserInfo(state) {
    return state.auth?.user;
  },
};
